import { Col, Row } from 'react-bootstrap'
import './App.css'
import Scanner from './components/Scanner'

function App() {
  return (
    <>
      <Row>
        <Col md="12" id="content-block">
          <Scanner />
        </Col>
      </Row>
    </>
  )
}

export default App
