export async function postDataApi(url = '', jwt, type, data) {
  // Default options are marked with *
  return (
    await fetch(url, {
      method: type, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data),
    })
  ).json() // parses JSON response into native JavaScript objects
}
