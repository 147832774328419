import React, { useState, useRef } from 'react'
import QrScanner from 'qr-scanner'
import { useEffect } from 'react'
import parse from 'html-react-parser'
import { postDataApi } from '../lib/transport'
import config from '../config'
import Modal from 'react-bootstrap/Modal'

const url = new URL(
  'https://' + config.API_PATH + '/api/v1/events/participants/check'
)

const Scanner = () => {
  const ref = useRef(0)
  const appState = useRef(true)
  const [modalText, setModalText] = useState("")
  const [modalShow, setModalShow] = useState(false)
  function closeModal() {
    setModalShow(false)
    appState.current = true
    ref.current.start()
  }

  function sendData(data) {
    if (data && appState.current) {
      appState.current = true
      postDataApi(url, config.VRCCI_JWT, 'POST', { uniq: data })
        .then((res) => {
          ref.current.stop()
          setModalText(res.log)
          setModalShow(true)
        })
        .finally(() => {
          appState.current = false
        })
    }
    return
  }

  useEffect(() => {
    const video = document.getElementById('qr-video')
    const qrScanner = new QrScanner(video, (result) => sendData(result.data), {
      highlightCodeOutline: true,
      highlightScanRegion: true,
      maxScansPerSecond: 0.5,
    })
    qrScanner.start()
    ref.current = qrScanner
  }, [])
  return (
    <>
      <video id="qr-video" className="video_canvas"></video>
      <Modal show={modalShow} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Результат</Modal.Title>
        </Modal.Header>
        <Modal.Body>{parse (modalText)}</Modal.Body>
      </Modal>
    </>
  )
}

export default Scanner
